import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import "./ScrollAnimation.css";
import { Stack } from "@mui/material";

const ScrollAnimation = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const { scroll } = useSelector(state => state.root);
  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (!userHasScrolled) {
        setShowAnimation(true);
      }
    }, 10000);

    return () => clearTimeout(timerRef.current);
  }, [userHasScrolled]);

  useEffect(() => {
    if (scroll !== 0 && !userHasScrolled) {
      setUserHasScrolled(true);
      setShowAnimation(false);
      clearTimeout(timerRef.current);
    } else if (scroll === 0 && userHasScrolled) {
      setUserHasScrolled(false);
      timerRef.current = setTimeout(() => {
        setShowAnimation(true);
      }, 10000);
    }
  }, [scroll, userHasScrolled]);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const IconToDisplay = !isMobile ? KeyboardDoubleArrowUpIcon : PanToolAltIcon;


  return (
    showAnimation ? (
      <Stack sx={{position:'absolute'}} flex={1} direction={"row"} justifyContent={'center'} className={"animationContainer"}>
        <IconToDisplay className="hand-animation" />
      </Stack>
    ) : null
  );
}

export default ScrollAnimation;
