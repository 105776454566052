/* eslint-disable jsx-a11y/alt-text */
import classes from "./style/first.module.css"
import { CharacterReveal } from "react-text-reveal"
import logoM from "./style/logo.module.css"
import {
  Stack, Typography,
} from "@mui/material"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import ScrollAnimation from "../../UI/ScrollAnimation/ScrollAnimation"
import { setScroll } from "../../store/slices/RootSlice"
import * as l from '../../lang/translations.json'
import { Fade } from "react-awesome-reveal";
import NavBar from "./NavBar"
import DragAnimation from "../../UI/DragAnimation/DragAnimation"



const Portfolio = ({ initFirstDialog, onClickP }) => {
  const dispatch = useDispatch();
  const { ui, lang } = useSelector(state => state.root);
  const isSmallScreen = false;

  return (
    <>
      {!ui && <DragAnimation />}
      <NavBar onClickP={onClickP} />
      {ui &&
        <div
          onScroll={e => {
            dispatch(
              setScroll(
                e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
              )
            )
          }}
          className={classes.maincontainer}
        >
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={'100vh'}
          >
            <CharacterReveal
              className={`${logoM.textMain} ${logoM.logoMain}`}
              animateOpacity={true}
              canPlay={initFirstDialog}
              copy={["IO", "DE", "DO"]}
              direction={"bottom"}
              duration={1300} // ms
              ease={"cubic-bezier(0.26,0,0.4,1)"}
              offset={"65px"}
              multilineMasking={false}
              multilineOffsetDelay={200} // ms
            />

            <Fade triggerOnce>
              <Typography variant="h4" gutterBottom>
                {l[lang].concept_portfolio}</Typography>
            </Fade>
            <ScrollAnimation />
          </Stack>
          <Stack
            height={'100vh'}
            direction={"column"}
            justifyContent={"center"}
            sx={{ pt: '10vh' }}
          >
            <Fade triggerOnce style={{ textAlign: 'start', paddingLeft: '10vw', paddingRight: '10vw', lineHeight: '6vh' }}>
              <Typography variant="h4" gutterBottom>
                {l[lang].as_a_passionate_person}</Typography>
            </Fade>
            <Fade delay={500} triggerOnce style={{ textAlign: 'start', paddingLeft: '10vw', paddingRight: '10vw', lineHeight: '6vh' }}>
              <Typography variant="h4" gutterBottom>
                {l[lang].project_during_pandemic}</Typography>
            </Fade>
          </Stack>
          <Stack
            direction={"column"}
            justifyContent={"center"}
          >
            <Fade triggerOnce>
              <Typography variant="h4" gutterBottom style={{ paddingLeft: '10vw', paddingRight: '10vw', lineHeight: '6vh' }} className={`${logoM.text}`}>{l[lang].some_tools_useful}</Typography>
              <Stack direction={"column"} spacing={2} gap={2} sx={{ mt: '5vh', p: '5vw' }}>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} spacing={2} justifyContent={'space-around'}>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img className={classes.imgSize} src="/model/image/html.webp" />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>HTML5</h3>
                      <div />
                      <h3>
                        {moment().format("YYYY") - 2017} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>

                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img className={classes.imgSize} src="/model/image/css.webp" />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>CSS3</h3>

                      <h3>
                        {moment().format("YYYY") - 2017} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/javascript.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>Javascript</h3>

                      <h3>
                        {moment().format("YYYY") - 2017} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                </Stack>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} spacing={2} justifyContent={'space-around'}>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        style={{ filter: `invert(1)` }}
                        src="/model/image/three.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>Three.js</h3>

                      <h3>
                        {moment().format("YYYY") - 2019} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/webgl.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>WebGl</h3>

                      <h3>
                        {moment().format("YYYY") - 2021} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/react.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>React</h3>

                      <h3>
                        {moment().format("YYYY") - 2022} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                </Stack>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} spacing={2} justifyContent={'space-around'}>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/typescript.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>Typescript</h3>

                      <h3>
                        {moment().format("YYYY") - 2022} year
                      </h3>
                    </div>
                  </Stack>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/aftereffects.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>After Effects</h3>

                      <h3>
                        {moment().format("YYYY") - 2016} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/Illustrator.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>Illustrator</h3>

                      <h3>
                        {moment().format("YYYY") - 2017} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                </Stack>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} spacing={2} justifyContent={'space-around'}>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/premier.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>Premier</h3>

                      <h3>
                        {moment().format("YYYY") - 2019} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                  <Stack direction={'column'}>
                    <span style={{ position: "relative" }}>
                      <img
                        className={classes.imgSize}
                        src="/model/image/blender.webp"
                      />
                    </span>
                    <div className={classes.skillTextContainer}>
                      <h3>Blender</h3>

                      <h3>
                        {moment().format("YYYY") - 2022} {l[lang].years_experience}
                      </h3>
                    </div>
                  </Stack>
                </Stack>
              </Stack>
            </Fade>
          </Stack>
          <div style={{ height: '100vh' }} />
        </div>}
    </>

  )
}

export default Portfolio
