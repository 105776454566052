/* eslint-disable import/no-anonymous-default-export */
export default /* glsl */ `

uniform sampler2D uTexture;
varying vec2 vUv;
uniform float uOpacity;


void main() {
      float alpha = uOpacity;

      vec3 texture = texture2D(uTexture, vUv).rgb;
	  gl_FragColor = vec4(texture2D,alpha);
}
`
