import {
  useGLTF,
  PerspectiveCamera
} from "@react-three/drei"
import { useRef, useEffect, useState } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import * as THREE from "three"
import { useSelector } from "react-redux"
import { useMediaQuery, useTheme } from "@mui/material"
import HeadOnFire from "../firecamp/HeadOnFire"

export default function NewScene({ startFadeOut, fadeIn, ...props }) {
  const { scroll } = useSelector((state) => state.root);
  const group = useRef()
  const [opacity] = useState(1)
  const { nodes, animations } = useGLTF("/model/forestfinale.glb")
  const [opacityScroll, setOpacityScroll] = useState(scroll >= 0.20 ? 0 : 1 - (scroll / 0.20));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { size } = useThree();

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({
        x: (event.clientX / size.width) * 2 - 1,
        y: -(event.clientY / size.height) * 2 + 1
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [size.width, size.height]);

  useFrame((state) => {
    const { camera } = state;
    camera.position.set(-25.614110946655273-(scroll * 6), 13.429999351501465, 27.7490177154541);
    camera.lookAt(new THREE.Vector3(-27.614110946655273, 13.429999351501465, 27.7490177154541));
  });

  useEffect(() => {
    setOpacityScroll(scroll >= 0.20 ? 0 : 1 - (scroll / 0.20))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll])



  useEffect(
    () => {
      if (group.current) {
        group.current.traverse(child => {
          if (child.material) {
            child.material.opacity = opacity
            child.material.transparent = true
          }
        })

      }
      if (opacity === 0) {
        var delayInMilliseconds = 500; //1 second

        setTimeout(function () {
          fadeIn()
        }, delayInMilliseconds);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [opacity]
  )

  return opacity !== 0 && <>
    <group ref={group} {...props} dispose={null}>
      <HeadOnFire
        firecamp={[nodes.Rock, nodes.Trunks, nodes.Fire]}
        animations={animations}
        opacity={opacityScroll} mousePosition={mousePosition}
      />
      <PerspectiveCamera
        name="CameraAll"
        makeDefault={true}
        far={1000}
        near={.5}
        fov={isSmallScreen ? 60 : 40}
        rotation={[-Math.PI / 2, 0, 0]}
      />
    </group></>

}
