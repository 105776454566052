import "./App.css"
import { Provider } from "react-redux";
import { store } from "./store";
import Scene from "./Scene"
import CustomCursor from "./UI/CustomCursor/CustomCursor";

function App() {
  return (
    <Provider store={store}>
      <CustomCursor />
      <Scene />
    </Provider>
  )
}

export default App
