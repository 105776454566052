import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import l from '../../lang/translations.json'; // Ensure this path is correct
import "./DragAnimation.css"; // Ensure CSS is loaded

const DragAnimation = () => {
  const { lang } = useSelector(state => state.root);

  return (
    <Stack
      sx={{ position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
      className="animationContainer"
    >
        <Typography className="animation">{l[lang].drag}</Typography>
    </Stack>
  );
}

export default DragAnimation;
