/* eslint-disable import/no-anonymous-default-export */
export default /* glsl */ `
uniform vec3 uColor1;
uniform vec3 uColor2;
	
varying vec3 vPosition;
varying float vNoise;
uniform float uOpacity;

void main() {
    float noiseIntensity = vNoise; // Valore di rumore passato dal vertex shader
    float alpha = mix(0.8, 1.0, noiseIntensity); // Ad esempio, varia l'opacità basandosi sull'intensità del rumore
    vec3 color = mix(uColor1, uColor2, noiseIntensity); // Mischiare i colori basati sull'intensità del rumore
    gl_FragColor = vec4(color, alpha * uOpacity); // Applica il colore e l'opacità al pixel
}
`
