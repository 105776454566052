import {
  useGLTF,
  PerspectiveCamera,
  useAnimations,
  OrbitControls
} from "@react-three/drei"

import { useRef, useEffect, useState } from "react"
import { useFrame, useLoader } from "@react-three/fiber"
import * as THREE from "three"
import { TextureLoader } from "three/src/loaders/TextureLoader"
import Firecamp from "../firecamp/Firecamp"
import Lake from "../lake/Lake"
import Trees from "../trees/Trees"
import Moon from "../moon/Moon"
import { useSelector } from "react-redux"
import { gsap } from "gsap";

export default function Forest({ userClicked, ...props }) {
  const { ui, scroll } = useSelector((state) => state.root);

  const elements = useRef()
  const { nodes, animations } = useGLTF("/model/forestfinale.glb")
  const terrainTexture = useLoader(TextureLoader, "/model/textures/Terrain.jpg")
  const treesTexture = useLoader(TextureLoader, "/model/textures/Trees.jpg")
  const houseTexture = useLoader(TextureLoader, "/model/textures/House.jpg")
  terrainTexture.flipY = false
  treesTexture.flipY = false
  houseTexture.flipY = false

  const { actions } = useAnimations(animations, elements)

  useEffect(
    () => void (actions["CameraAllActionAnim"].play().paused = true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useFrame(state => {
    actions["CameraAllActionAnim"].time = THREE.MathUtils.lerp(
      actions["CameraAllActionAnim"].time,
      actions["CameraAllActionAnim"].getClip().duration * scroll,
      0.009
    )
    const direction = new THREE.Vector3();
    state.camera.getWorldDirection(direction);

  })

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (userClicked) {
      gsap.to(elements.current.children, {
        duration: 3,
        ease: "power2.out",
        onStart: () => {
          elements.current.traverse(child => {
            if (child.material) child.material.transparent = true;
          });
        },
        onUpdate: () => {
          let opacity
          try {
            opacity = gsap.getProperty(elements.current.children[0], "opacity");

          } catch (error) {
            opacity = 1
          }
          setOpacity(opacity);
        },
        opacity: 1
      });
    }
  }, [userClicked]);

  const [isSecondCameraActive, setIsSecondCameraActive] = useState(true);
  const secondCameraRef = useRef();

  useEffect(() => {
    setIsSecondCameraActive(!isSecondCameraActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui]);

  return (
    <>
      {isSecondCameraActive && (
        <PerspectiveCamera
          ref={secondCameraRef}
          position={[0, 40, 30]}
          fov={50}
          makeDefault // Questo assicura che diventi la camera di default quando è attiva
        />
      )}
      {isSecondCameraActive && (
        <OrbitControls
          minDistance={10}
          maxDistance={50}
          enabled={true}
          target={new THREE.Vector3(0, 10, 0)}
          maxPolarAngle={Math.PI / 2.5}
          enableZoom={false}
          enablePan={false}
          camera={secondCameraRef.current}
        />
      )}
      <group ref={elements} {...props} dispose={null}>
        <Trees trees={nodes.Trees} opacity={opacity} />
        <mesh geometry={nodes.Terrain.geometry}>
          <meshBasicMaterial map={terrainTexture} transparent={false} opacity={1} />
        </mesh>
        <mesh geometry={nodes.House.geometry}>
          <meshBasicMaterial map={houseTexture} opacity={opacity} />
        </mesh>
        <Moon moon={nodes.Sphere} opacity={opacity} />
        <Firecamp
          firecamp={[nodes.Rock, nodes.Trunks, nodes.Fire]}
          animations={animations}
          opacity={opacity < .8 ? opacity : .8}
        />
        <Lake />
        <group name={"CameraAll"}>
          <group name="CameraAll_Orientation">
            {!isSecondCameraActive && (
              <PerspectiveCamera
                name="CameraAll"
                makeDefault={true}
                far={(opacity + 500) * 2}
                near={.5}
                fov={40}
                rotation={[-Math.PI / 2, 0, 0]}
              />)}
          </group>
        </group>
      </group></>
  )
}