/* eslint-disable import/no-anonymous-default-export */
export default /* glsl */ `

uniform vec3 uColor1;
uniform vec3 uColor2;
	
varying vec3 vPosition;
varying float vNoise;
	
void main() {
	float alpha = 1.0;
	float gradient = mix(0.,1.,-vPosition.y+3.5);
	vec3 color = mix(uColor1,uColor2,.1);
	if(vNoise > 0.1 * gradient) {
		color = mix(uColor2,uColor1,.9);
	}
	if(vNoise > 0.2 * gradient) {
		color = mix(uColor2,uColor1,.5);
	}
	if(vNoise > 0.25 * gradient) {
		color = mix(uColor2,uColor1,.1);
	}
	gl_FragColor = vec4(color, alpha);
}
`;