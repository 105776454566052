/* eslint-disable import/no-anonymous-default-export */
export default /* glsl */ `

varying vec3 vPos;
uniform float uTime; // Time in seconds

mat4 getRotationMatrixY(float angle) {
  float s = sin(angle);
  float c = cos(angle);

  return mat4(
      c, 0.0, -s, 0.0,
      0.0, 1.0, 0.0, 0.0,
      s, 0.0, c, 0.0,
      0.0, 0.0, 0.0, 1.0
  );
}

void main() {
  float angle = uTime;

  vPos = position;
  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
  mvPosition = getRotationMatrixY(angle) * mvPosition;
  gl_Position = projectionMatrix * mvPosition;
}
`
