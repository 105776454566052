import {
  shaderMaterial,
  Sphere
} from "@react-three/drei";
import { useFrame, extend } from "@react-three/fiber";
import * as THREE from "three";
import { useEffect, useRef } from "react";
import skydomeVertexShader from "./shaders/vertex.js";
import skydomeFragmentShader from "./shaders/fragment.js";

const SkyDomeMaterial = shaderMaterial(
  {
    skyRadius: 50.0,
    env_c1: new THREE.Color(0x041d36),
    env_c2: new THREE.Color(0x020e1a),
    noiseOffset: new THREE.Vector3(80.0,80.0,80.0),
    starSize: 0.01,
    starDensity: 0.09,
    clusterStrength: 0.2,
    clusterSize: 0.9,
    uTime:0.0,
    uOpacity: 1.0,
  },
  skydomeVertexShader,
  skydomeFragmentShader
);

extend({ SkyDomeMaterial });

export default function SkyDome({ opacity }) {
  const skyDomeMaterial = useRef();
  useFrame((state, delta) => {
    skyDomeMaterial.current.uniforms.uTime.value += delta * 0.01;
  });

  useEffect(() => {
    if(skyDomeMaterial.current) {
      skyDomeMaterial.current.uniforms.uOpacity.value = opacity; 
    }
  }, [opacity]);

  return (
    <Sphere args={[150, 150, 150]}>
      <skyDomeMaterial ref={skyDomeMaterial} side={THREE.BackSide} />
    </Sphere>
  );
}