import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  scroll: 0,
  ui: true,
  lang: navigator.language.substring(0, 2)
}

export const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setScroll(state, action) {
      state.scroll = action.payload
    },
    setControlsEnabled(state, action) {
      state.ui = !state.ui
    },
    setLang(state, action) {
      state.lang = action.payload
    },

  }
})

export const { setScroll, setControlsEnabled, setLang } = RootSlice.actions

export default RootSlice.reducer
