import React, { useEffect, useState } from 'react';

const Loading = ({ isLoading, progress, handleClose }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (progress >= 100 && !fadeOut) {
      setFadeOut(true);
      setTimeout(() => {
        handleClose(false);
      }, 1000);
    }
  }, [progress, fadeOut, handleClose]);

  return (
    <div
      style={{
        background: "#000000",
        position: "absolute",
        zIndex: 1,
        height: "100vh",
        width: "100vw"
      }}
    >
      <div
        className={`bar ${fadeOut && "fadeOut"}`}
        style={{
          position: 'absolute',
          left: '10%',
          width: '80%',
          height: '20px',
          backgroundColor: 'rgba(255,255,255,0.2)',
          top: "0",
          bottom: "0",
          margin: "auto"
        }}
      >
        <div
          style={{
            height: '100%',
            width: `${progress}%`,
            backgroundColor: 'rgba(255,255,255,1)',
            transition: 'width 0.5s ease'
          }}
        />
      </div>
    </div>
  );
};

export default Loading;
