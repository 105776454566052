import "./App.css"
import { Canvas } from "@react-three/fiber"
import { Suspense, useEffect, useState } from "react"

import SkyDome from "./components/skydome/SkyDome.js"
import Forest from "./components/forest/Forest.js"
import NewScene from "./components/head/NewScene.js"
import Careers from "./components/overlay/Careers.js"
import Portfolio from "./components/overlay/Portfolio.js"


import {
  DepthOfField,
  Bloom,
  EffectComposer,
  SMAA
} from "@react-three/postprocessing"
import { useDispatch } from "react-redux";
import { setScroll } from "./store/slices/RootSlice"
import { useProgress } from "@react-three/drei"
import Loading from "./components/loading/Loading"


function Scene() {
  const dispatch = useDispatch()

  const [userClicked, setUserClicked] = useState(false)
  const [startFadeOut, setStartFadeOut] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isLoading, load] = useState(true)
  const [loader, setLoader] = useState(true)

  const handleUserClick = () => {
    setStartFadeOut(true)
  }

  const Loader = ({ load }) => {
    const { progress } = useProgress()
    setProgress(progress)
    useEffect(() => {
      load(true)
      return () => load(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  }

  const textsToUpdate = [
    `🚀_________`,
    `_🚀________`,
    `__🚀_______`,
    `___🚀______`,
    `O___🚀_____`,
    `DO___🚀____`,
    `EDO___🚀___`,
    `DEDO___🚀__`,
    `ODEDO___🚀_`,
    `IODEDO___🚀`
  ]

  useEffect(() => {
    const updateWithDelay = async () => {
      for (const text of textsToUpdate) {
        await new Promise(resolve => setTimeout(resolve, 1000))
        document.title = text
      }
    }
    updateWithDelay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {(loader) &&
        <Loading
          isLoading={isLoading}
          progress={progress}
          handleClose={() => {
            setLoader(false)
          }}
        />}
      <Canvas
        onClick={handleUserClick}
        dpr={[1, Math.min(window.devicePixelRatio, 2)]}
        style={{
          height: "100vh",
          background: "#000000",
          position: "absolute",
          zIndex: 0
        }}
      >
        <SkyDome opacity={1} />
        {!userClicked
          ? <Suspense fallback={<Loader load={load} />}>
            <NewScene
              startFadeOut={startFadeOut}
              fadeIn={() => {
                setUserClicked(true)
              }}
            />
          </Suspense>
          : <Suspense fallback={<Loader load={load} />}><Forest
            userClicked={true}
          />
          </Suspense>}
        <EffectComposer multisampling={0}>
          <SMAA />
          <Bloom mipmapBlur intensity={1.9} luminanceThreshold={0.3} />
          <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={1.5} />
        </EffectComposer>
      </Canvas>
      {!userClicked ? <Careers initFirstDialog={true} onClickP={() => {
        dispatch(setScroll(0))
        setUserClicked(true)
      }} /> : <Portfolio initFirstDialog={true} onClickP={() => {
        dispatch(setScroll(0))
        setUserClicked(false)
      }} />}
    </>
  )
}

export default Scene
