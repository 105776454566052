/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react"
import classes from "./style/first.module.css"
import { Box, Button, Stack, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material"
import RotatingText from "../../UI/RotatingText/RotatingText"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { setScroll } from "../../store/slices/RootSlice"
import ScrollAnimation from "../../UI/ScrollAnimation/ScrollAnimation"
import TabPanel from "../../UI/TabPanel/TabPanel"
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import VideoCarousel from "../../UI/Carousel/VideoCarousel"
import ImageCarousel from "../../UI/Carousel/ImageCarousel"
import { JackInTheBox, Fade } from "react-awesome-reveal";

import * as l from '../../lang/translations.json'
import NavBar from "./NavBar"

const Careers = (props) => {
  const { onClickP } = props;
  const dispatch = useDispatch()
  const { scroll, lang } = useSelector((state) => state.root);
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Stack id="main"
        style={{
          height: "100vh",
          opacity: scroll >= 0.20 ? 0 : 1 - (scroll / 0.20),
          transition: 'opacity 0.5s ease-in-out',
          overflowX: "hidden",
          overflowY: "auto"
        }} direction={"column"}>
        <NavBar hide={true} onClickP={() => { }} />
        <Stack direction={"row"} sx={{
          height: "100vh",
          width: "100%",
          maxWidth: "500px",
          position: "absolute",
          margin: "auto",
          right: 0,
          left: 0
        }}>
          <RotatingText />
        </Stack>
        <ScrollAnimation />
      </Stack>
      <div
        onScroll={e => {
          dispatch(setScroll(e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)))
        }}
        className={classes.maincontainer}
        style={{ marginTop: "-100vh" }}
      >
        <div style={{ height: "150vh" }} />

        <Stack
          style={{
            minHeight: "30vh",
            padding: "3vw",
            paddingBottom: 0,
            textAlign: "left"
          }} direction={"column"} justifyContent={'start'} alignContent={'left'} spacing={2}>
          <JackInTheBox delay={400} >
            <Typography variant="h4" gutterBottom>{l[lang].first_character_reveal.replace('{years}', moment().format("YYYY"))}</Typography>
          </JackInTheBox>
        </Stack>
        <Stack direction={"column"} p={'5vw'} alignItems={"center"} justifyContent={"center"} gap={'10vh'}>
          <Fade delay={200} style={{ width: '100%' }}>
            <Stack sx={{ width: '100%' }}>
              <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={value} onChange={handleChange} variant={isSmallScreen ? "scrollable" : "fullWidth"} aria-label="logo tabs example" scrollButtons={isSmallScreen ? "auto" : false}>
                  <Tab sx={{ height: '6vh' }} icon={<img src="../model/image/ebuk_logo.png" style={{ height: "7vh", filter: 'invert(1)' }} />} aria-label="Ebuk" />
                  <Tab sx={{ height: '6vh' }} icon={<img src="../model/image/logicweb_logo.png" style={{ height: "4vh" }} />} aria-label="LogicWeb" />
                  <Tab sx={{ height: '6vh' }} icon={<img src="../model/image/texa_logo.jpeg" style={{ height: "4vh" }} />} aria-label="Texa" />
                  <Tab sx={{ height: '6vh' }} icon={<img src="../model/image/logo_bludata.png" style={{ height: "3vh" }} />} aria-label="BluData" />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} className="carousel_bg" spacing={2}>
                  <Stack direction={"column"} flex={1} alignContent={'center'} justifyContent={'center'} sx={{ textAlign: 'left' }}>
                    <VideoCarousel />
                    <Typography variant="h4" gutterBottom>
                      {l[lang].tab_description_ebuk_details}
                    </Typography>
                    <List dense>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>FrontEnd:</strong> jQuery, Bootstrap e Three.js
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].ui_design}:</strong> Adobe Illustrator
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>Marketing:</strong> {l[lang].events_and_social_media}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].video_production}:</strong> {l[lang].adobe_after_effects}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].audio_editing}:</strong> Adobe Audition
                        </Typography>
                      </ListItem>
                    </List>
                    <Typography variant="h4" gutterBottom>
                      {l[lang].freedom_and_responsibility_project}
                    </Typography>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} className="carousel_bg" spacing={2}>
                  <Stack direction={"column"} flex={1} alignContent={'center'} justifyContent={'center'} sx={{ textAlign: 'left' }}>
                    <ImageCarousel images={["../model/image/mainLogicWeb.png", "../model/image/niceLogicWeb.png"]} />
                    <Typography variant="h4" gutterBottom>
                      {l[lang].logicweb_details}
                    </Typography>
                    <List dense>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>FrontEnd:</strong> Angular TypeScript
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>App: </strong>
                          {" "}{l[lang].ionic_app_development}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].animation_library}:</strong>{" "}GSAP
                        </Typography>
                      </ListItem>
                    </List>
                    <Typography variant="h4" gutterBottom>
                      {l[lang].object_oriented_programming_experience}
                    </Typography>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} className="carousel_bg" spacing={2}>
                  <Stack direction={"column"} flex={1} alignContent={'center'} justifyContent={'center'} sx={{ textAlign: 'left' }}>
                    <ImageCarousel images={["../model/image/mainTexa.png", "../model/image/secondTexa.png", "../model/image/thirdTexa.png"]} />
                    <Typography variant="h4" gutterBottom>
                      {l[lang].tab_description_texa}
                    </Typography>
                    <List dense>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>FrontEnd:</strong> {l[lang].time_reporting_website_development}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>BackEnd:</strong> {l[lang].asp_net_service_development}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].documentation}:</strong> {l[lang].audio_visual_documentation_production}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>Power Platform:</strong> {l[lang].power_app_creation}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].video_production}:</strong>{" "}{l[lang].adobe_after_effects}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].audio_editing}:</strong> Adobe Audition
                        </Typography>
                      </ListItem>
                    </List>
                    <Typography variant="h4" gutterBottom>
                      {l[lang].large_company_experience}
                    </Typography>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Stack direction={isSmallScreen ? "column" : "row"} flex={1} className="carousel_bg" spacing={2}>
                  <Stack direction={"column"} flex={1} alignContent={'center'} gap={3} justifyContent={'center'} sx={{ textAlign: 'left' }}>
                    <ImageCarousel images={["../model/image/mainBludata.png", "../model/image/secondBludata.png"]} />
                    <Typography variant="h4" gutterBottom>
                      {l[lang].middle_frontend_developer}
                    </Typography>
                    <List dense>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>FrontEnd:</strong> {l[lang].optical_center_management}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="h5">
                          <strong>{l[lang].training}:</strong>  {l[lang].colleague_training}
                        </Typography>
                      </ListItem>
                    </List>
                    <Typography variant="h4" gutterBottom>
                      {l[lang].complex_app_development}
                    </Typography>
                  </Stack>
                </Stack>
              </TabPanel>
            </Stack>
          </Fade>
          <Stack
            style={{
              minHeight: "50vh",
            }} direction={"column"} justifyContent={'center'} alignContent={'center'} spacing={2}>
            <JackInTheBox >

              <Typography variant="h4" gutterBottom>
                {l[lang].portfolio_project}
              </Typography>
            </JackInTheBox>
            <Button
              onClick={() => {
                onClickP()
              }}>{l[lang].discover_more}</Button>
          </Stack>
        </Stack>
      </div>
    </>
  )
}

export default Careers
