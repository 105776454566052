import { configureStore } from "@reduxjs/toolkit"
import RootSlice from "./slices/RootSlice"
import { useSelector } from "react-redux"

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    root: RootSlice
  }
})

export const useAppSelector = useSelector
