import {
  shaderMaterial,
} from "@react-three/drei"
import * as THREE from "three"
import { extend } from "@react-three/fiber"
import { useRef } from "react"
import moonVertexShader from "./shaders/vertex.js"
import moonFragmentShader from "./shaders/fragment.js"

const MoonMaterial = shaderMaterial(
  {
    uTime: Math.random(20,50),
    uNoise: 3.2,
    uSpeed: 1.3,
    uColor2: new THREE.Color("#000000"),
    uColor1: new THREE.Color("#ffe09e"),
  },
  moonVertexShader,
  moonFragmentShader
)

extend({ MoonMaterial })

export default function Moon(props) {
  const { moon } = props
  const group = useRef()
  const moonMaterial = useRef()

  return (
    <group ref={group}>
      <mesh
        name="Sphere"
        geometry={moon.geometry}
        position={[moon.position.x+15,moon.position.y+0,moon.position.z+15]}
        scale={8}
        >

        <moonMaterial ref={moonMaterial} />
      </mesh>
    </group>
  )
}
