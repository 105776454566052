import { useEffect, useRef, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { PlayArrow, Pause, VolumeUp, VolumeOff } from "@mui/icons-material";
import "./Carousel.css";

const videos = [
  { src: "../model/video/chat.mp4", id: "videoElement1" },
  { src: "../model/video/ebuk3.mp4", id: "videoElement2" }
];

function VideoCarousel() {
  const [index, setIndex] = useState(0);
  const videoRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(true);
  const [videoMuted, setVideoMuted] = useState(true);

  const togglePlay = () => {
    if (videoPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setVideoPlaying(!videoPlaying);
  };

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setVideoMuted(videoRef.current.muted);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoPlaying) {
        nextVideo();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [index, videoPlaying]);

  const nextVideo = () => {
    const nextIndex = index === videos.length - 1 ? 0 : index + 1;
    setIndex(nextIndex);
  };

  // Play the video when the index changes
  useEffect(() => {
    if (videoPlaying) {
      //videoRef.current.play();
    }
  }, [index]);

  return (
    <Stack direction={"column"} alignItems="end" justifyContent="center">
      <video
        ref={videoRef}
        className={"carousel_glass"}
        style={{ width: "100%", height: "30vh" }}
        autoPlay
        muted={videoMuted}
        loop
        src={videos[index].src}
      />
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={togglePlay} color="primary">
          {videoPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
        <IconButton onClick={toggleMute} color="primary">
          {videoMuted ? <VolumeOff /> : <VolumeUp />}
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default VideoCarousel;
