import { shaderMaterial } from "@react-three/drei"
import treeVertexShader from "./shaders/vertex.js"
import treeFragmentShader from "./shaders/fragment.js"
import * as THREE from "three"
import { extend, useFrame, useLoader } from "@react-three/fiber"
import { useEffect, useRef } from "react"
import { TextureLoader } from "three/src/loaders/TextureLoader"

const TreeMaterial = shaderMaterial(
  {
    time: 0.0,
    uOpacity: 1.0,
    uTexture: new THREE.Texture()
  },
  treeVertexShader,
  treeFragmentShader
)

extend({ TreeMaterial })

export default function Trees(props) {
  const { trees,opacity } = props
  
  const bakedTexture = useLoader(TextureLoader, "/model/textures/Trees.jpg")
  bakedTexture.flipY = false

  const treeMaterial = useRef()

  useFrame((state, delta) => {
    treeMaterial.current.time += delta
  })

  useEffect(() => {
    if(treeMaterial.current) {
      treeMaterial.current.uniforms.uOpacity.value = opacity;
    }
  }, [opacity]);

  return (
    <mesh geometry={trees.geometry}>
      <treeMaterial
        ref={treeMaterial}
        uTexture={bakedTexture}
        position={[
          trees.position.x,
          trees.position.y,
          trees.position.z
        ]}
      />
    </mesh>
  )
}
