import { shaderMaterial, useGLTF, useAnimations } from "@react-three/drei"
import * as THREE from "three"
import { useFrame, extend, useLoader } from "@react-three/fiber"
import { useRef } from "react"
import fireVertexShader from "./shaders/vertex.js"
import fireFragmentShader from "./shaders/fragment.js"
import { useEffect } from "react"
import { TextureLoader } from "three/src/loaders/TextureLoader"

const FireMaterial = shaderMaterial(
  {
    uTime: 0.0,
    uNoiseDensity: 0.0,
    uNoiseStrength: 0.0,
    uNoise: 3.2,
    uSpeed: 1.3,
    uOpacity: 1.0,
    uColor2: new THREE.Color("#ffe808"),
    uColor1: new THREE.Color("#ff5a00")
  },
  fireVertexShader,
  fireFragmentShader
)

extend({ FireMaterial })

export default function HeadOnFire(props) {
  const { firecamp, animations, opacity, mousePosition } = props

  const elements = useRef()
  const fireMaterial = useRef()
  const headRef = useRef()
  const fireRef = useRef()

  const { nodes } = useGLTF("/model/forest.glb")
  const { actions } = useAnimations(animations, elements)

  const headTexture = useLoader(TextureLoader, "/model/textures/io.png")
  headTexture.flipY = false

  useFrame((_, delta) => {
    actions["FireRotation"].play()
    fireMaterial.current.uTime += 0.5
    if (fireMaterial.current.uNoiseDensity < 0.5) {
      fireMaterial.current.uNoiseDensity += delta
    }
    if (fireMaterial.current.uNoiseStrength < 1.2) {
      fireMaterial.current.uNoiseStrength += delta
    }

    if (headRef.current && fireRef.current) {
      const clampedY = THREE.MathUtils.clamp(mousePosition.y * 0.2, -0.2, 0.2);
      const clampedX = THREE.MathUtils.clamp(mousePosition.x * 0.4, -0.4, 0.2);
      headRef.current.rotation.y = clampedX;
      headRef.current.rotation.z = clampedY;

      fireRef.current.position.x = headRef.current.position.x - .16;
      fireRef.current.position.y = headRef.current.position.y + .1;

      fireRef.current.rotation.x = headRef.current.rotation.x;
      fireRef.current.rotation.y = headRef.current.rotation.y;
      fireRef.current.rotation.z = headRef.current.rotation.z;
    }
  })

  useEffect(
    () => {
      if (fireMaterial.current) {
        fireMaterial.current.uniforms.uOpacity.value = opacity
      }
    },
    [opacity]
  )
  return (
    <group ref={elements}>
      <mesh
        ref={fireRef}
        name="Fire"
        position={[nodes.head.position.x, nodes.head.position.y, nodes.head.position.z]}
        geometry={firecamp[2].geometry}
      >
        <fireMaterial ref={fireMaterial} />
      </mesh>
      <mesh
        ref={headRef}
        geometry={nodes.head.geometry}
        position={[nodes.head.position.x, nodes.head.position.y, nodes.head.position.z]}
        scale={nodes.head.scale}
      >
        <meshBasicMaterial map={headTexture} opacity={opacity} />
      </mesh>
    </group>
  )
}
