import { useEffect, useState } from "react"
import { IconButton, Stack } from "@mui/material"
import { PlayArrow, Pause } from "@mui/icons-material"
import "./Carousel.css"

function ImageCarousel({ images }) {
  const [index, setIndex] = useState(0)
  const [slidePlaying, setSlidePlaying] = useState(true)

  const toggleSlideShow = () => {
    setSlidePlaying(!slidePlaying)
  }

  useEffect(
    () => {
      if (!slidePlaying) return
      const timer = setInterval(() => {
        nextSlide()
      }, 5000)

      return () => clearInterval(timer)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slidePlaying, index]
  )

  const nextSlide = () => {
    setIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
  }

  return (
    <Stack direction={"column"} alignItems="end" justifyContent="center">
      <Stack
        direction={"column"}
        justifyContent="center"
        className={"carousel_glass"}
        alignItems={"center"}
        sx={{
          width: "100%",
          height: "300px",
          maxHeight: "25vh",
          backgroundImage: "url(" + images[index] + ")",
          backgroundPosition: "center",
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat"
        }}
      />
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={toggleSlideShow} color="primary">
          {slidePlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default ImageCarousel
