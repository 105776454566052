import { useRef, useMemo } from "react";
import { extend, useLoader, useFrame } from "@react-three/fiber";
import * as THREE from "three";

import { Water } from "three/examples/jsm/objects/Water.js";
import { TextureLoader } from "three/src/loaders/TextureLoader"

extend({ Water });

export default function Lake() {
  const ref = useRef();
  const waterNormals = useLoader(TextureLoader,"/model/textures/waternormals2.jpg");

  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.CircleGeometry(40,20), []);
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      waterColor: 0x041d36,
      distortionScale: 0.5,
      fog: true,
      sunColor: 0x041d36,
      
    reflectionIntensity: 0,
    refractionIntensity: 0,
    }),
    [waterNormals]
  );
  useFrame((state, delta) => {
    const material = ref.current.material;
    material.uniforms.time.value += delta * 0.3;
})
  return (
    <>
      <water
        ref={ref}
        args={[geom, config]}
        rotation-x={-Math.PI / 2}
        position={[0, 7.45, 20]}
      />
    </>
  );
}
