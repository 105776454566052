import { shaderMaterial, useAnimations } from "@react-three/drei"
import * as THREE from "three"
import { useFrame, extend, useLoader } from "@react-three/fiber"
import { useRef } from "react"
import fireVertexShader from "./shaders/vertex.js"
import fireFragmentShader from "./shaders/fragment.js"
import { useEffect } from "react"
import { TextureLoader } from "three/src/loaders/TextureLoader"

const FireMaterial = shaderMaterial(
  {
    uTime: 0.0,
    uNoiseDensity: 0.0,
    uNoiseStrength: 0.0,
    uNoise: 3.2,
    uSpeed: 1.3,
    uOpacity: 1.0,
    uColor2: new THREE.Color("#ffe808"),
    uColor1: new THREE.Color("#ff5a00")
  },
  fireVertexShader,
  fireFragmentShader
)

extend({ FireMaterial })

export default function Firecamp(props) {
  const { firecamp, animations, opacity } = props

  const elements = useRef()
  const fireMaterial = useRef()

  const { actions } = useAnimations(animations, elements)

  const rocksTexture = useLoader(TextureLoader, "/model/textures/Rocks.jpg")
  const trunksTexture = useLoader(TextureLoader, "/model/textures/Trunks.jpg")
  const test = useLoader(TextureLoader, "/model/textures/test.jpg")

  rocksTexture.flipY = false
  trunksTexture.flipY = false
  test.flipY = false

  useFrame((_, delta) => {
    actions["FireRotation"].play()
    fireMaterial.current.uTime += 0.5
    if (fireMaterial.current.uNoiseDensity < 0.5) {
      fireMaterial.current.uNoiseDensity += delta
    }
    if (fireMaterial.current.uNoiseStrength < 1.2) {
      fireMaterial.current.uNoiseStrength += delta
    }
  })

  useEffect(() => {
    if (fireMaterial.current) {
      fireMaterial.current.uniforms.uOpacity.value = opacity;
      fireMaterial.current.transparent = opacity < 1.0;
    }
  }, [opacity]);
  

  return (
    <group ref={elements}>
      <mesh geometry={firecamp[0].geometry}>
        <meshBasicMaterial map={rocksTexture} opacity={opacity} />
      </mesh>
      <mesh geometry={firecamp[1].geometry}>
        <meshBasicMaterial map={trunksTexture} opacity={opacity} />
      </mesh>
      <mesh
        name="Fire"
        geometry={firecamp[2].geometry}
        position={firecamp[2].position}
        scale={firecamp[2].scale}
      >
        <fireMaterial ref={fireMaterial} />
      </mesh>
    </group>
  )
}
