/* eslint-disable import/no-anonymous-default-export */
export default /* glsl */ `
uniform float time;
varying vec2 vUv;

float N (vec2 st){
    return fract(sin(dot(st.xy,vec2(12.9898,78.233))) * 43758.5353123);
}

float smoothNoise (vec2 ip){
    vec2 lv = fract(ip);
    vec2 id = floor (ip);
    lv = lv * lv * (3. - 2. * lv);
    float bl = N(id);
    float br = N( id + vec2(1.,0.));
    float b = mix(bl,br,lv.x);
    float tl = N(id + vec2(0.,1.));
    float tr = N(id + vec2(1.,1.));
    float t = mix(tl,tr,lv.x);
    return mix(b,t,lv.y);
}

void main(){
    vUv = uv;
    float t = time * 1.4;
    vec4 movPosition = vec4(position.x,position.y-.6,position.z, 1.0);
    #ifdef USE_INSTANCING
    movPosition = instanceMatrix * movPosition;
    #endif

    float noise = smoothNoise(movPosition.xy * 0.3 + vec2(0.,t));
    noise = pow(noise * 0.9, 2.) * 2.;

    float dispPower = .6 - cos(uv.x * 1.1416);
    float displacement = noise / .8 * (dispPower * .12);
    movPosition.xz -= displacement;


    vec4 modelViewPosition = modelViewMatrix * movPosition;
    gl_Position = projectionMatrix * modelViewPosition;
}

`
