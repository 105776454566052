import React, { useEffect, useRef } from 'react';
import './CustomCursor.css';

const CustomCursor = () => {
    const cursorRef = useRef(null);

    useEffect(() => {
        const hideCursorOnMobile = window.matchMedia("(max-width: 768px)").matches;
        const onMouseMove = (e) => {
            if (cursorRef.current) {
                cursorRef.current.style.left = `${e.clientX}px`;
                cursorRef.current.style.top = `${e.clientY}px`;
            }
        };

        if (!hideCursorOnMobile) {
            document.addEventListener("mousemove", onMouseMove);

            return () => {
                document.removeEventListener("mousemove", onMouseMove);
            };
        } else {
            if (cursorRef.current) {
                cursorRef.current.classList.add('hidden');
            }
        }
    }, []);

    return <div ref={cursorRef} className="cursor"></div>;
};

export default CustomCursor;
