import { Divider, IconButton, Menu, MenuItem, Stack } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setControlsEnabled, setLang } from "../../store/slices/RootSlice";
import { useEffect, useRef, useState } from "react";
import classes from "./style/first.module.css"
import logoM from "./style/logo.module.css"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import TranslateIcon from '@mui/icons-material/Translate';
import { VolumeUp, VolumeOff } from "@mui/icons-material"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import night from './sound/night.mp4';
import fire from './sound/fire.mp4';


import { CharacterReveal } from "react-text-reveal"

const NavBar = (props) => {
    const { hide, onClickP } = props
    const dispatch = useDispatch()
    const { ui } = useSelector((state) => state.root);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [audioStatus, setAudioStatus] = useState(false);
    const nightRef = useRef();
    const fireRef = useRef();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLanguageChange = (language) => {
        dispatch(setLang(language));
        handleClose();
    };

    const toggleAudio = () => {
        if (hide) return;
        if (audioStatus) {
            nightRef.current.pause();
            fireRef.current.pause();
        } else {
            nightRef.current.play();
            fireRef.current.play();
        }
        setAudioStatus(!audioStatus);
    };

    useEffect(() => {
        if (hide) return;
        nightRef.current.play();
        fireRef.current.play();
        setAudioStatus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <audio loop
            ref={nightRef}
            src={night}
        />
        <audio
            loop
            ref={fireRef}
            src={fire}
        />
        <Stack
            direction={"row"}
            className={classes.navcontainer}
            spacing={1}
            alignItems={"center"}
            sx={{ position: 'absolute' }}
        >
            {ui && <Stack sx={{ cursor: 'pointer' }} onClick={() => {
                onClickP()
            }}>
                <CharacterReveal
                    className={`${logoM.text} ${logoM.logo}`}
                    animateOpacity={true}
                    canPlay={true}
                    copy={["IO", "DE", "DO"]}
                    direction={"bottom"}
                    duration={1300}
                    ease={"cubic-bezier(0.26,0,0.4,1)"}
                    offset={"65px"}
                    multilineMasking={false}
                    multilineOffsetDelay={200}
                />
            </Stack>}
            <div style={{ flex: 1 }} />
            {ui && <IconButton color="primary" onClick={() => { }}>
                <LinkedInIcon />
            </IconButton>}
            {ui && <IconButton color="primary" onClick={() => (window.location = "mailto:iodedo@icloud.com")}>
                <RocketLaunchIcon />
            </IconButton>}
            {ui && <IconButton color="primary"
                onClick={handleClick}>
                <TranslateIcon />
            </IconButton>}
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'language-button',
                }}
            >
                <MenuItem onClick={() => handleLanguageChange('it')}>Italiano 🇮🇹</MenuItem>
                <MenuItem onClick={() => handleLanguageChange('en')}>English 🇬🇧</MenuItem>
            </Menu>
            {ui && <>
                <Divider sx={{ height: '70%', display: hide ? 'none' : 'flex', background: 'white' }} orientation="vertical" />
                <IconButton sx={{ display: hide ? 'none' : 'flex' }} color="primary" onClick={toggleAudio}>
                    {audioStatus ? <VolumeUp /> : <VolumeOff />}
                </IconButton>
            </>}
            <IconButton sx={{ display: hide ? 'none' : 'flex' }} color="primary" onClick={() => {
                dispatch(setControlsEnabled());
            }}>
                <RemoveRedEyeIcon />
            </IconButton>
        </Stack>
    </>
}

export default NavBar