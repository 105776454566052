import React from "react"
import "./RotatingText.css"
import { useSelector } from "react-redux";
import * as l from '../../lang/translations.json'

const RotatingText = () => {
  const { lang } = useSelector((state) => state.root);
  return (
    <svg viewBox="0 0 100 100" width="100" height="100" className="circle rotating">
      <defs>
        <path
          id="circlePath"
          d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0"
        />
        <filter id="glow">
          <feGaussianBlur stdDeviation="0.3" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <text
        fontSize="4.7"
        wordSpacing="2"
        fill="white"
        style={{ filter: "url(#glow)" }}
      >
        <textPath href="#circlePath">
          <tspan style={{ fontFamily: "Roboto Slab" }}>
            {l[lang].first}
          </tspan>
          <tspan
            style={{ fontFamily: "Museo Slab", fill: "white" }}
            fontSize="7"
          >
            {l[lang].second}
          </tspan>
          <tspan style={{ fontFamily: "Roboto Slab" }}>{l[lang].third}</tspan>
          <tspan
            style={{ fontFamily: "Museo Slab", fill: "white" }}
            fontSize="7"
          >
            {l[lang].fourth}
          </tspan>
          <tspan style={{ fontFamily: "Roboto Slab" }}>{l[lang].fifth}</tspan>
          <tspan
            style={{ fontFamily: "Museo Slab", fill: "white" }}
            fontSize="7"
          >
            {`${l[lang].sixth} `}
          </tspan>
        </textPath>
      </text>
    </svg>
  )
}

export default RotatingText
